import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import useRepos from "../../hooks/use-repositories"

const SnippetsPage = () => {
  const { edges } = useRepos()

  const metadata = {
    title: "Laboratorio",
    description:
      "En este sitio puede encontrar pequeños programas, soluciones discretas y experimentos de código en los que puede colaborar o seguir su propio camino desde la base del código.",
    cover: "/images/covers/cover-nicolascodes.png",
    lang: "es",
    type: "website",
    path: "/snippets",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Laboratorio"
        content="En este sitio puede encontrar pequeños programas, soluciones discretas y experimentos de código en los que puede colaborar o seguir su propio camino desde la base del código."
      />
      <Grids edges={edges} snippets />
    </Layout>
  )
}

export default SnippetsPage
